
import { nextTick, onMounted, onUnmounted, reactive, ref } from "vue"
import UserGroupService from "@/services/UserGroupService"
import ResultSet from "@/models/ResultSet"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import UserGroupForm from "./UserGroupForm.vue"
import { useStore } from "vuex"

export default {
  name: "UserGroupCollection",
  components: { PrimaryButton, UserGroupForm },
  props: {
    companies: {
      type: Array,
      default: [],
    },
  },
  setup(props: any) {
    let part = 0
    const sort = ref("")
    const userGroupService = new UserGroupService()
    const userGroups = reactive([])
    const loading = ref(true)
    const store = useStore()
    const defaultCompanyId = store.getters.getDefaultCompanyId
    const selectedCompany = ref(
      props.companies.find((company: any) => company.id === defaultCompanyId) ?? null
    )
    const scrollComponent = ref()
    const loadingPart = ref(false)
    const loadedAllData = ref(false)
    const addOrEditUserGroupDialog = ref(false)
    const selectedUserGroup = ref(null)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }
      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }
      window.removeEventListener("resize", checkVisible)
    })

    function checkVisible() {
      if (loadedAllData.value) return
      if (loadingPart.value) return
      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart()
      }
    }

    function reload() {
      loadingPart.value = true
      part = 0
      addOrEditUserGroupDialog.value = false
      loadedAllData.value = false
      userGroups.length = 0
      selectedUserGroup.value = null
      loadPart()
    }

    loadPart()

    function loadPart() {
      if (selectedCompany.value) {
        userGroupService
          .getUserGroups(
            part,
            sort.value,
            "",
            selectedCompany.value ? selectedCompany.value.id : null
          )
          .then((result: ResultSet<any>) => {
            onPartLoaded(result)
          })
      } else {
        loadingPart.value = false
        loadedAllData.value = true
      }
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome
      if (!result.rows.length) {
        return
      }
      part++

      result.rows.forEach((userGroup: any) => {
        userGroups.push(userGroup)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function newUserGroup() {
      selectedUserGroup.value = null
      addOrEditUserGroupDialog.value = true
    }

    function onRowClick() {
      addOrEditUserGroupDialog.value = true
    }

    return {
      userGroups,
      loading,
      selectedCompany,
      reload,
      addOrEditUserGroupDialog,
      newUserGroup,
      selectedUserGroup,
      onRowClick,
    }
  },
}
