
import ResultSet from "@/models/ResultSet"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { onMounted, reactive, ref } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import EmployeeService from "@/services/EmployeeService"
import TertiaryButton from "../buttons/TertiaryButton.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import UserGroupService from "@/services/UserGroupService"

export default {
  name: "UserGroupForm",
  components: { LinkLabel, TertiaryButton, PrimaryButton },
  emits: ["reload-user-group-col"],
  props: {
    company: Object,
    userGroup: {
      type: Object,
      default: null,
    },
  },
  setup(props: any, { emit }: any) {
    const employeeService = new EmployeeService()
    const userGroupService = new UserGroupService()
    const loading = ref(true)
    const timeout = ref(null)
    let part = 0
    const selectedItems = ref(props.userGroup ? props.userGroup.users : null)
    const sort = ref("")
    const filter = ref("")
    const employees = reactive([])

    const form = ref({
      alias: props.userGroup ? props.userGroup.alias : null,
    })

    const rules = {
      alias: { aliasRequired: helpers.withMessage("The alias is required", required) },
    }

    const v$: any = useVuelidate(rules, form)

    getAllEpmloyees()

    onMounted(() => {
      loading.value = false
    })

    function reload() {
      part = 0
      employees.length = 0
      getAllEpmloyees()
    }

    function getAllEpmloyees() {
      loading.value = true
      employeeService
        .getAll(props.company.id, part, sort.value, filter.value, 10000)
        .then((result: ResultSet<any>) => {
          loading.value = false
          if (result.rows.length) {
            result.rows.forEach((employee: any) => {
              employees.push(employee)
            })
          }
        })
    }

    function search() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        reload()
      }, 300)
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    function saveUserGroup() {
      v$.value.$touch()
      if (v$.value.$invalid) return
      if (!props.userGroup) {
        userGroupService.postUserGroup(form.value, props.company.id).then((result: any) => {
          if (result && selectedItems.value?.length) {
            let requestArray: any = []
            selectedItems.value.forEach((item: any) => {
              requestArray.push(userGroupService.postUserToUserGroup(result, item.id))
            })
            Promise.all(requestArray).then(() => {
              emit("reload-user-group-col")
            })
          }
        })
      } else {
        if (props.userGroup.alias !== form.value.alias) {
          userGroupService.editUserGroup(props.userGroup.id, form.value).then(() => {
            let requestArray: any = compareEmployeeArrays()
            Promise.all(requestArray).then(() => {
              emit("reload-user-group-col")
            })
          })
        } else {
          let requestArray: any = compareEmployeeArrays()
          Promise.all(requestArray).then(() => {
            emit("reload-user-group-col")
          })
        }
      }
    }

    function compareEmployeeArrays() {
      let requestArray: any = []
      let updateEmployeesObj: any = {
        addEmployees: [],
        deleteEmployees: [],
      }
      if (props.userGroup.users?.length) {
        props.userGroup.users.forEach((employee: any) => {
          if (!selectedItems.value.find((item: any) => item.id === employee.id))
            updateEmployeesObj.deleteEmployees.push(employee.id)
        })
      }
      if (selectedItems.value?.length) {
        selectedItems.value.forEach((employee: any) => {
          if (!props.userGroup.users?.find((item: any) => item.id === employee.id))
            updateEmployeesObj.addEmployees.push(employee.id)
        })
      }

      updateEmployeesObj.addEmployees.forEach((item: any) => {
        requestArray.push(userGroupService.postUserToUserGroup(props.userGroup.id, item))
      })
      updateEmployeesObj.deleteEmployees.forEach((item: any) => {
        requestArray.push(userGroupService.deleteUserOfUserGroup(props.userGroup.id, item))
      })

      return requestArray
    }

    function deleteUserGroup() {
      userGroupService.deleteUserGroupById(props.userGroup.id).then(() => {
        emit("reload-user-group-col")
      })
    }

    const onRowSelect = (event: any, isNew = false) => {
      const selectedItem = event.data ?? event
      if (isNew) {
        selectedItems.value = [...selectedItems.value, selectedItem]
      }
    }

    return {
      v$,
      loading,
      selectedItems,
      filter,
      employees,
      search,
      sortColumn,
      saveUserGroup,
      onRowSelect,
      deleteUserGroup,
    }
  },
}
